import { useSelector } from 'react-redux';

import _ from 'lodash';
import { format } from 'generic/utils/dateUtils';
import { t } from 'i18next';
import {
  generateLinkToResource, getValueByBaseFieldsConfig, getValueOrFirstValueFromArray,
} from 'generic/utils/qesUtils';

const useDocumentFields = (document) => {
  const { qesdocument, base: docBaseId } = document;

  const bases = useSelector((state) => state.config.bases);
  const documentBase = _.find(bases, { base: docBaseId });

  // noms des champs issues de la configuration de la base, permet d'être un peu dynamique
  // et avec de la chance, ne rien avoir à surcharger
  const id = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champId');
  const statusXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champEtat');
  const titleXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champTitre');
  const pdf = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champPdf');

  // noms des champs en durs, difficile de les rendre dynamique en l'état de l'API
  // on prend le premier élément du tableau de valeur s'il y en a plusieur.
  const text = getValueOrFirstValueFromArray(qesdocument?.RESPONSE || qesdocument?.Fichier_joint_TEXT);
  const datePubXml = getValueOrFirstValueFromArray(qesdocument?.PUB_DATE);
  const thumbXml = getValueOrFirstValueFromArray(qesdocument?.IMAGE || qesdocument?.THUMB);
  const linkout = getValueOrFirstValueFromArray(qesdocument?.URL || qesdocument?.QUOTE_URL);
  const source = getValueOrFirstValueFromArray(qesdocument?.SOURCE);
  const criticality = Number(_.get(qesdocument, 'Niveau_criticite[0]', -1));

  // dernières retouches avant le retour du résulat
  const thumb = generateLinkToResource(docBaseId, thumbXml);
  const title = titleXml || `[${t('document.title_undefined')}]`;
  const titleTruncate = _.truncate(title, {
    length: 50,
    separator: /,? +/,
  });
  const status = +statusXml;
  const date = format(datePubXml);
  const snippet = _.truncate(text, {
    length: 250,
    separator: /,? +/,
  });

  const criticalityColorMap = {
    1: 'success',
    2: 'info',
    3: 'warning',
    4: 'error',
  };
  const chipColor = _.get(criticalityColorMap, criticality, '');

  return {
    criticality,
    chipColor,
    date,
    id,
    pdf,
    thumb,
    snippet,
    status,
    text,
    title,
    titleTruncate,
    linkout,
    source,
    docBaseId,
  };
};

export default useDocumentFields;
