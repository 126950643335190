import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import {
  AppBar,
  Box,
  ClickAwayListener,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import {
  Done,
  DoneAll,
  FormatListBulleted,
  Help,
  HelpOutlineOutlined,
  Menu,
  Notifications,
  PowerSettingsNew,
  QueryStats,
  UploadFile,
} from '@mui/icons-material';
import clsx from 'clsx';
import {
  Link, NavLink, useHistory, useLocation,
} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import logo from 'custom/ica/img/logoICA.png';
import ToggleMobileSearchIconContainer from 'generic/containers/ToggleMobileSearchIconContainer';
import DropdownMenu from 'generic/components/ui/DropdownMenu';

import { fetchResults } from 'generic/core/search/actions';
import UploadFilesToCreateDocumentsDialogContainer
  from 'generic/containers/UploadFilesToCreateDocumentsDialogContainer';
import { openDialogUploadFilesToCreateDocuments } from 'generic/core/actions/actions';
import HeaderSearchWrapper from 'generic/components/ui/HeaderSearchWrapper';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiToolbar-root': {
      paddingLeft: '0',
      paddingRight: '20px',
    },
  },
  logo: {
    marginRight: '16px',
    borderRadius: '50px',
  },
  linkLogo: {
    marginLeft: '0',
    height: '64px',
    backgroundColor: '#0097a7',
    padding: '0 50px',
  },
}));

const PersonalSpaceMenu = ({ handleClickSearch }) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Fragment>
      <MenuItem onClick={() => handleClickSearch(22801201, 1)}>
        <ListItemIcon>
          <HelpOutlineOutlined fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('ged.document.status.1')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleClickSearch(22801201, 50)}>
        <ListItemIcon>
          <Done fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('ged.document.status.50')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleClickSearch(22801201, 1001)}>
        <ListItemIcon>
          <Help fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('ged.document.status.1001')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleClickSearch(22801201, 100)}>
        <ListItemIcon>
          <DoneAll fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('ged.document.status.100')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem
        component={NavLink}
        to="/alerts"
        selected={location.pathname.startsWith('/alerts')}
      >
        <ListItemIcon>
          <Notifications fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('header.alerts')}</ListItemText>
      </MenuItem>
    </Fragment>
  );
};

PersonalSpaceMenu.propTypes = {
  handleClickSearch: PropTypes.func.isRequired,
};

const LinksMenu = ({ handleClickSearch, handleClickUpload }) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Fragment>
      <PersonalSpaceMenu handleClickSearch={handleClickSearch} />

      <MenuItem
        component={NavLink}
        to={`/dashboard${location?.search || ''}`}
        selected={location.pathname === '/dashboard'}
      >
        <ListItemIcon>
          <QueryStats fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('header.dashboard')}</ListItemText>
      </MenuItem>

      <MenuItem
        component={NavLink}
        to={`/results${location?.search || ''}`}
        selected={location.pathname === '/results'}
      >
        <ListItemIcon>
          <FormatListBulleted fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('header.results')}</ListItemText>
      </MenuItem>

      <MenuItem
        onClick={handleClickUpload}
      >
        <ListItemIcon>
          <UploadFile fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('form.upload')}</ListItemText>
      </MenuItem>
    </Fragment>
  );
};

LinksMenu.propTypes = {
  handleClickSearch: PropTypes.func.isRequired,
  handleClickUpload: PropTypes.func.isRequired,
};

const Header = () => {
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(true);
  const classes = useStyles();
  const theme = useTheme();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation();

  useEffect(() => {
    setIsSearchInputVisible(!smallerThanLarge);
  }, [smallerThanLarge]);

  const forceHideMenusForMobile = smallerThanLarge && isSearchInputVisible;
  const forceShowMenusForMobile = smallerThanLarge && !isSearchInputVisible;

  const handleClickSearch = (baseId, statusValue) => {
    push('/results');
    dispatch(fetchResults({
      bodyItems: {
        premier: 1,
        dernier: 50,
        base: baseId,
        champs: {
          F_110: statusValue,
        },
      },
      refreshForm: true,
      clearSelection: true,
      clearResults: true,
    }));
  };

  const handleClickUpload = () => {
    dispatch(openDialogUploadFilesToCreateDocuments());
  };

  return (
    <Fragment>
      <ClickAwayListener
        onClickAway={() => (forceHideMenusForMobile ? setIsSearchInputVisible(false) : _.noop)}
        mouseEvent="onMouseDown"
      >
        <Box width="100%" className={classes.root}>
          <AppBar elevation={0} position="static">
            <Toolbar
              className={clsx('displayFlex', 'justifyContentSpaceBetween')}
            >
              <Fragment>
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <Link
                    to="/home"
                    className={classes.linkLogo}
                    style={{ display: forceHideMenusForMobile ? 'none' : 'flex' }}
                  >
                    <img
                      src={logo}
                      className={classes.logo}
                      alt="Qwam Logo"
                    />
                  </Link>
                  <DropdownMenu
                    btnChildren={<Menu />}
                    btnProps={{
                      sx: {
                        display: forceShowMenusForMobile ? 'flex' : 'none',
                        padding: '8px',
                        color: '#ffffff',
                      },
                    }}
                  >
                    <LinksMenu handleClickSearch={handleClickSearch} handleClickUpload={handleClickUpload} />
                  </DropdownMenu>

                  {!smallerThanLarge && (
                    <Fragment>
                      <DropdownMenu
                        btnChildren={<MenuItem sx={{ color: 'white' }}>{t('ica.header.my_space')}</MenuItem>}
                      >
                        <PersonalSpaceMenu handleClickSearch={handleClickSearch} />
                      </DropdownMenu>
                      <MenuItem
                        onClick={handleClickUpload}
                      >
                        <ListItemIcon>
                          <UploadFile sx={{ color: 'white' }} fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('form.upload')}</ListItemText>
                      </MenuItem>
                    </Fragment>
                  )}
                </Box>
                <HeaderSearchWrapper showSearchInput={isSearchInputVisible} />
                <Box display="flex" alignItems="center">
                  <ToggleMobileSearchIconContainer
                    handleClick={() => setIsSearchInputVisible(true)}
                    isVisible={forceShowMenusForMobile}
                  />
                  <Box
                    sx={{
                      display: forceHideMenusForMobile ? 'none' : 'inline-block',
                    }}
                  >
                    <Tooltip
                      title={t('account_menu.logout')}
                    >
                      <MenuItem
                        component={NavLink}
                        to="/logout"
                      >
                        <PowerSettingsNew fontSize="small" />
                      </MenuItem>
                    </Tooltip>
                  </Box>
                </Box>
              </Fragment>
            </Toolbar>
          </AppBar>
        </Box>
      </ClickAwayListener>
      <UploadFilesToCreateDocumentsDialogContainer
        baseId={22801208}
        baseMisePageId={7936310}
      />
    </Fragment>
  );
};

export default Header;
