const en = {
  en: {
    translation: {
      ica: {
        header: {
          my_space: 'My space',
        },
        home: {
          involved_function: 'Involved<br/>function',
          criticality: 'Criticality',
          progression: 'Progression',
        },
        criticality: {
          1: {
            color: 'success',
            name: 'non-structuring',
          },
          2: {
            color: 'info',
            name: 'to consider',
          },
          3: {
            color: 'warning',
            name: 'important',
          },
          4: {
            color: 'error',
            name: 'crucial',

          },
        },
      },
      route: {
        app_name: 'ICA',
      },
      ged: {
        document: {
          status: {
            1: 'To answer',
            50: 'To validate',
            1001: 'To complete',
            100: 'Completed',
          },
        },
      },
    },
  },
};

export default en;
