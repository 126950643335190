const primary = '#263238';
const secondary = '#0097a7';

const themeConfig = {
  THEME: {
    components: {
    },
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
      text: {
        dark: 'rgba(0, 0, 0, 0.87)',
        neutral: 'rgba(0, 0, 0, 0.54)',
      },
    },
  },
};

export default themeConfig;
