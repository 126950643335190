import React, { useEffect } from 'react';

import { useTheme } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import {
  Grid,
  useMediaQuery,
} from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

import QES_CONSTANTS from 'generic/core/qes/constants';
import { fetchResults, fetchResultsComplete } from 'generic/core/search/actions';
import { fetchWidgets } from 'generic/core/dashboard/actions';

import DashboardWidget from 'generic/components/dashboard-items/DashboardWidget';
import DocumentList from 'generic/components/dashboard-items/DocumentList';
import Gauge from 'generic/components/dashboard-items/SolidGauge';
import Pie from 'generic/components/dashboard-items/Pie';
import WidgetContainer from 'generic/containers/WidgetContainer';
import GEDModalContainer from 'generic/containers/GEDModalContainer';
import { fastGedOpen } from 'generic/core/ged/actions';

const { DEFAULT_SEARCH_FULL_BASE_UNPARSED } = QES_CONSTANTS;

const BASE_QUESTIONS = 22801201;

HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const strategy = DEFAULT_SEARCH_FULL_BASE_UNPARSED;
const widgetsForAllScreens = {
  toAnswerDocuments: {
    additionalQuery: 'ETAT:1',
    slice: 500,
    sort: 'DATE_COLLECT Desc',
    type: 'documentlist',
  },
  toValidateDocuments: {
    additionalQuery: 'ETAT:50',
    slice: 500,
    sort: 'DATE_COLLECT Desc',
    type: 'documentlist',
  },
  toCompleteDocuments: {
    additionalQuery: 'ETAT:1001',
    slice: 500,
    sort: 'DATE_COLLECT Desc',
    type: 'documentlist',
  },
};
const widgetsForLargeScreens = {
  documentGaugeStatus: {
    facets: 'ETAT',
    aggregates: ['agg_ETAT'],
    type: 'solidgauge',
  },
  criticalityLevel: {
    facets: 'Niveau_criticite',
    aggregates: ['agg_Niveau_criticite'],
    type: 'pie',
    list: 1001701310,
  },
  functionInvolved: {
    facets: 'Fonctions_concernees.verbatim',
    aggregates: ['agg_Fonctions_concernees.verbatim'],
    type: 'pie',
  },
};
const widgetsToFetch = widgetsForAllScreens;
Object.assign(widgetsToFetch, widgetsForLargeScreens);

const HomeContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => dispatch(fetchWidgets(strategy, widgetsToFetch, true, BASE_QUESTIONS)), [dispatch]);
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));

  const raffinageParams = {
    premier: 1,
    dernier: 50,
    tranche: 50,
    base: BASE_QUESTIONS,
  };

  return (
    <Grid
      container
      flexWrap="nowrap"
    >
      <Grid item flex={1} sx={{ overflowY: 'scroll' }}>
        <WidgetContainer widgetId="toAnswerDocuments" fullHeight flat>
          {({ series: toAnswerDocs }) => (
            <DashboardWidget
              flat
              component={DocumentList}
              componentProps={{
                title: t('ged.document.status.1'),
                qesdocuments: toAnswerDocs[0],
                handleDocumentClick: (documentIdext) => dispatch(fetchResultsComplete(documentIdext, BASE_QUESTIONS)),
                handleOpenFastGed: (documentIdext) => dispatch(fastGedOpen(documentIdext, BASE_QUESTIONS)),
                groupField: 'Theme_de_question',
                fullHeight: true,
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item flex={1} sx={{ overflowY: 'scroll' }}>
        <WidgetContainer widgetId="toValidateDocuments" fullHeight flat>
          {({ series: toValidateDocs }) => (
            <DashboardWidget
              flat
              component={DocumentList}
              componentProps={{
                title: t('ged.document.status.50'),
                qesdocuments: toValidateDocs[0],
                handleDocumentClick: (documentIdext) => dispatch(fetchResultsComplete(documentIdext, BASE_QUESTIONS)),
                handleOpenFastGed: (documentIdext) => dispatch(fastGedOpen(documentIdext, BASE_QUESTIONS)),
                groupField: 'Theme_de_question',
                fullHeight: true,
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      <Grid item flex={1} sx={{ overflowY: 'scroll' }}>
        <WidgetContainer widgetId="toCompleteDocuments" fullHeight flat>
          {({ series: toCompleteDocs }) => (
            <DashboardWidget
              flat
              component={DocumentList}
              componentProps={{
                title: t('ged.document.status.1001'),
                qesdocuments: toCompleteDocs[0],
                handleDocumentClick: (documentIdext) => dispatch(fetchResultsComplete(documentIdext, BASE_QUESTIONS)),
                handleOpenFastGed: (documentIdext) => dispatch(fastGedOpen(documentIdext, BASE_QUESTIONS)),
                groupField: 'Theme_de_question',
                fullHeight: true,
              }}
            />
          )}
        </WidgetContainer>
      </Grid>

      {isLarge && (
        <Grid
          item
          flex={1}
          sx={{
            overflowY: 'scroll', rowGap: '5px', display: 'flex', flexDirection: 'column',
          }}
        >
          <WidgetContainer widgetId="documentGaugeStatus" height={270} flat>
            {({
              series: documentGaugeStatusSerie,
              axisX,
              height,
            }) => (
              <DashboardWidget
                flat
                height={height}
                component={Gauge}
                highchartsOptions={{
                  options: {
                    exporting: {
                      enabled: false,
                    },
                    chart: {
                      height,
                    },
                    pane: {
                      background: axisX.categories.map((aggregate, index) => ({
                        outerRadius: aggregate.outerRadius,
                        innerRadius: aggregate.innerRadius,
                        borderWidth: 0,
                        backgroundColor: `${Highcharts.getOptions().colors[9 - (index * 2)]}4D`,
                      })),
                    },
                    tooltip: {
                      enabled: false,
                    },
                    legend: {
                      // eslint-disable-next-line func-names, object-shorthand
                      labelFormatter: function () {
                        // eslint-disable-next-line react/no-this-in-sfc
                        return `${t(`ged.document.status.${this.name}`)} - ${this.data[0].y}%`;
                      },
                      layout: 'vertical',
                      align: 'right',
                      verticalAlign: 'bottom',
                      itemMarginBottom: 10,
                    },
                    series: documentGaugeStatusSerie[0].data.map((aggregate, index) => ({
                      point: {
                        events: {
                          click: (event) => {
                            dispatch(fetchResults({
                              bodyItems: {
                                ...raffinageParams,
                                champs: { F_110: event.point.name },
                              },
                              refreshForm: true,
                              clearResults: true,
                              clearSelection: true,
                            }));
                            history.push('/results');
                          },
                        },
                      },
                      name: aggregate.name,
                      data: aggregate.data.map((aggdata) => ({
                        radius: aggdata.radius,
                        innerRadius: aggdata.innerRadius,
                        name: aggdata.name,
                        y: aggdata.y,
                        color: Highcharts.getOptions().colors[9 - (index * 2)],
                      })),
                      color: Highcharts.getOptions().colors[9 - (index * 2)],
                      showInLegend: true,
                    })),
                    title: { text: t('ica.home.progression') },
                  },
                }}
              />
            )}
          </WidgetContainer>

          <WidgetContainer widgetId="criticalityLevel" height={310} flat>
            {({ series: criticalitySerie, height }) => (
              <DashboardWidget
                flat
                height={height}
                component={Pie}
                highchartsOptions={{
                  options: {
                    exporting: {
                      enabled: false,
                    },
                    chart: {
                      height,
                    },
                    plotOptions: {
                      pie: {
                        data: criticalitySerie[0].data,
                        point: {
                          events: {
                            click: (event) => {
                              dispatch(fetchResults({
                                bodyItems: {
                                  ...raffinageParams,
                                  champs: { F_1032000576: event.point.options.strategy },
                                },
                                refreshForm: true,
                                clearResults: true,
                                clearSelection: true,
                              }));
                              history.push('/results');
                            },
                          },
                        },
                      },
                    },
                    series: [
                      { name: '' },
                    ],
                    title: {
                      text: t('ica.home.criticality'),
                      verticalAlign: 'middle',
                      floating: true,
                    },
                  },
                }}
              />
            )}
          </WidgetContainer>

          <WidgetContainer widgetId="functionInvolved" height={310} flat>
            {({ series: functionSerie, height }) => (
              <DashboardWidget
                flat
                height={height}
                component={Pie}
                highchartsOptions={{
                  options: {
                    exporting: {
                      enabled: false,
                    },
                    chart: {
                      height,
                    },
                    plotOptions: {
                      pie: {
                        data: functionSerie[0].data,
                        point: {
                          events: {
                            click: (event) => {
                              dispatch(fetchResults({
                                bodyItems: {
                                  ...raffinageParams,
                                  champs: { F_1032000570: event.point.options.strategy },
                                },
                                refreshForm: true,
                                clearResults: true,
                                clearSelection: true,
                              }));
                              history.push('/results');
                            },
                          },
                        },
                      },
                    },
                    series: [
                      { name: '' },
                    ],
                    title: {
                      text: t('ica.home.involved_function'),
                      verticalAlign: 'middle',
                      floating: true,
                    },
                  },
                }}
              />
            )}
          </WidgetContainer>
        </Grid>
      )}

      <GEDModalContainer
        afterSave={() => {
          dispatch(fetchWidgets(strategy, widgetsToFetch, true, BASE_QUESTIONS));
        }}
      />
    </Grid>
  );
};

export default HomeContainer;
