import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Typography,
} from '@mui/material';
import {
  AccountBalance,
  Place,
  Label,
  AccountCircle,
  ExitToApp,
  Business,
  People,
  AttachFile,
} from '@mui/icons-material';
import ExpandableTagBox from 'generic/components/ui/ExpandableTagBox';
import CriticityState from 'custom/ica/components/ui/CriticalityState';
import { overrideRessource } from 'generic/utils/utils';
import { documentPropType } from 'custom/ica/core/qes/documentProptypes';

const { default: useDocumentFields } = overrideRessource('core/hooks/document', true);

const Document = ({
  document,
  displayTitle,
  context,
  handleGetDocumentComplete,
}) => {
  const {
    snippet, status, text, title, criticality,
  } = useDocumentFields(document);
  const { qesdocument } = document;

  return (
    <Box display="block" width="100%" sx={{ wordBreak: 'break-word', pt: 1 }}>
      {displayTitle && (
        <Box>
          { criticality && (
            <CriticityState
              criticality={criticality}
              state={status}
              sx={{ float: 'left', display: 'inline-flex' }}
            />
          )}

          <Typography
            variant="h6"
            component="span"
            fontSize="1.2rem"
            display="block"
            sx={{ ml: 1 }}
          >
            <Box
              onClick={handleGetDocumentComplete}
              sx={{ cursor: 'pointer' }}
              color="text.dark"
            >
              {title}
            </Box>
          </Typography>
        </Box>
      )}

      <Box display="flex" alignItems="flex-start">
        <Box flexGrow="1">
          <Box lineHeight="1.3rem">
            <Box
              display="flex"
              flexWrap="wrap"
              mb={0.5}
            >
              {qesdocument.Pilier && (
                <Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {qesdocument.Pilier[0]}
                  </Typography>
                  <Box mx={1} py="3px">
                    <Divider
                      orientation="vertical"
                    />
                  </Box>
                </Fragment>
              )}
              {qesdocument.Theme_de_question && (
                <Typography
                  component="span"
                  variant="body2"
                  color="text.neutral"
                >
                  {qesdocument.Theme_de_question[0]}
                </Typography>
              )}
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              mb={0.5}
            >
              {qesdocument.Format_de_la_reponse_attendue && (
                <Fragment>
                  <Box mr={1} py="3px">
                    <ExitToApp fontSize="8px" />
                  </Box>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {qesdocument.Format_de_la_reponse_attendue[0]}
                  </Typography>
                </Fragment>
              )}
              {qesdocument.Societe_objet && (
                <Fragment>
                  <Box mx={1} py="3px">
                    <Business fontSize="8px" />
                  </Box>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {qesdocument.Societe_objet[0]}
                  </Typography>
                </Fragment>
              )}
              {qesdocument.Fonctions_concernees && (
                <Fragment>
                  <Box mx={1} py="3px">
                    <People fontSize="8px" />
                  </Box>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {qesdocument.Fonctions_concernees[0]}
                  </Typography>
                </Fragment>
              )}
              {qesdocument.Pieces_a_fournir && qesdocument.Pieces_a_fournir[0] !== 'N/A' && (
                <Fragment>
                  <Box mx={1} py="3px">
                    <AttachFile fontSize="8px" />
                  </Box>
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.neutral"
                  >
                    {qesdocument.Pieces_a_fournir[0]}
                  </Typography>
                </Fragment>
              )}
            </Box>
            {context !== 'resultComplete' && (
              <div>
                {snippet}
              </div>
            )}
            <ExpandableTagBox
              disableRefine={['cart', 'newsletter'].includes(context)}
              qesdocument={qesdocument}
              tags={[
                { fieldName: 'QES_Company', icon: AccountBalance, fieldId: 1032000431 },
                { fieldName: 'QES_Person', icon: AccountCircle, fieldId: 901 },
                { fieldName: 'QES_Location', icon: Place, fieldId: 902 },
                { fieldName: 'QES_ConceptCategorized', icon: Label, fieldId: 30800115 },
              ]}
            />
            {context === 'resultComplete' && (
              <Box mt={1} mb={2} whiteSpace="pre-wrap" sx={{ clear: 'both' }}>
                {text}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Document.propTypes = {
  context: PropTypes.oneOf(['cart', 'newsletter', 'result', 'resultComplete']).isRequired,
  displayTitle: PropTypes.bool,
  document: documentPropType,
  handleGetDocumentComplete: PropTypes.func,
};

Document.defaultProps = {
  document: {},
  displayTitle: true,
  handleGetDocumentComplete: null,
};

export default Document;
