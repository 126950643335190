import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

const CriticityState = ({ criticality, state, sx }) => {
  const { t } = useTranslation();

  const criticalityObject = t(`ica.criticality.${criticality}`, { returnObjects: true });
  const statusFullname = t(`ged.document.status.${state}`);

  return (
    <Box
      sx={{
        display: 'flex', height: '1.25rem', mr: 1, ...sx,
      }}
    >
      { criticality && (
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: `${criticalityObject.color}.main`,
            color: `${criticalityObject.color}.contrastText`,
            display: 'flex',
            flexShrink: 0,
            height: '1.25rem',
            justifyContent: 'center',
            width: '1.25rem',
          }}
          title={criticalityObject.name}
        >
          <Typography fontSize="0.8rem">{criticality}</Typography>
        </Box>
      )}

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexShrink: 0,
          height: '1.25rem',
          justifyContent: 'center',
          backgroundColor: 'lightgray',
          px: 1,
          fontSize: '0.8125rem',
        }}
        title={statusFullname}
      >
        <Typography>{statusFullname[0]}</Typography>
      </Box>
    </Box>
  );
};

CriticityState.propTypes = {
  state: PropTypes.number.isRequired,
  criticality: PropTypes.number,
  sx: PropTypes.shape(),
};

CriticityState.defaultProps = {
  criticality: undefined,
  sx: {},
};

export default React.memo(CriticityState);
