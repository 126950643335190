import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { qesdocumentPropType } from 'generic/core/qes/proptypes';

import { Rsvp } from '@mui/icons-material';
import CriticityState from 'custom/ica/components/ui/CriticalityState';
import { overrideRessource } from 'generic/utils/utils';

const { default: useDocumentFields } = overrideRessource('core/hooks/document', true);

const DocumentForWidgetList = ({
  qesdocument,
  handleDocumentClick,
  openFastGed,
}) => {
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  // OBLIGÉ DE FAIRE UN OBJECT INTERMÉDIAIRE DÉGUEUX POUR TENTER D'AVOIR UN DOCUMENT
  // TYPE QES COMME DANS LES AUTRES ENDPOINTS
  const {
    title, titleTruncate, status, criticality, text,
  } = useDocumentFields({
    base: +qesdocument.BASE_ID,
    qesdocument: { ...qesdocument },
  });

  return (
    <Box display="block">
      <Box>
        <CriticityState
          criticality={criticality}
          state={status}
          sx={{ display: 'inline-flex' }}
        />

        <IconButton
          onClick={openFastGed}
          size="small"
        >
          <Rsvp
            size="small"
            color={text ? 'success' : 'action'}
          />
        </IconButton>

        <Typography
          component="span"
          fontSize="1rem"
          fontWeight="400"
          color="text.dark"
        >
          <Box
            component="span"
            sx={{ cursor: 'pointer' }}
            onClick={handleDocumentClick}
          >
            {smallerThanLarge ? titleTruncate : title}
          </Box>
        </Typography>
      </Box>

      <Typography
        mt={0}
        fontWeight="300"
        fontSize=".75rem"
        sx={{
          overflow: 'hidden',
          maxHeight: '3em',
          '&:after': {
            content: '""',
            display: 'block',
          },
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

DocumentForWidgetList.propTypes = {
  qesdocument: qesdocumentPropType,
  handleDocumentClick: PropTypes.func,
  openFastGed: PropTypes.func.isRequired,
};

DocumentForWidgetList.defaultProps = {
  qesdocument: {},
  handleDocumentClick: null,
};

export default DocumentForWidgetList;
