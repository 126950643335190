import PropTypes from 'prop-types';

const qesdocumentPropType = PropTypes.shape({
  Question: PropTypes.string,
  Niveau_criticite: PropTypes.string,
  Reponse: PropTypes.string,
});

const documentPropType = PropTypes.shape({
  qesdocument: qesdocumentPropType,
});

export {
  documentPropType,
  qesdocumentPropType,
};
