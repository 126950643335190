const fr = {
  fr: {
    translation: {
      ica: {
        header: {
          my_space: 'Mon espace',
        },
        home: {
          criticality: 'Criticité',
          involved_function: 'Fonction<br/>concernée',
          progression: 'Progression',
        },
        criticality: {
          1: {
            color: 'success',
            name: 'non structurant',
          },
          2: {
            color: 'info',
            name: 'à prendre en compte',
          },
          3: {
            color: 'warning',
            name: 'important',
          },
          4: {
            color: 'error',
            name: 'crucial',

          },
        },
        route: {
          app_name: 'ICA',
        },
      },
      ged: {
        document: {
          status: {
            1: 'À répondre',
            50: 'À valider',
            1001: 'À compléter',
            100: 'Complétées',
          },
        },
      },
    },
  },
};

export default fr;
